<template>
  <div class="margin">

    <b-tabs>
      <b-tab title="Dados">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <CheckboxGroup field="options" :formName="formName" :initialValue="collaborator.options" :options="[
              { text: 'Consultor', value: 'isConsultant' },
              { text: 'Freelancer', value: 'isFreelance' },
              { text: 'Funcionário', value: 'isEmployee' },
            ]" v-model="collaborator.options" />
          </b-col>
        </b-row>
        <Person :formName="formName" :showTypePerson="false" v-model="collaborator.personRequest" />
        <b-row>
          <b-col sm="12">
            <RadioGroup title="Status" field="status" :formName="formName" :options="[
              { text: 'Ativo', value: 1 },
              { text: 'Inativo', value: 2 },
            ]" v-model="collaborator.status" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Foto">
        <ImageUpload title="Carregar Imagem" classCss="img-round" container="nixloc-photo-collaborator" :width="100"
          :height="100" accepted=".jpg .png" urlPost="/api/v1/adm/file-upload/upload"
          urlRemove="/api/v1/adm/file-upload/delete" :onLoad="updateImage" v-model="collaborator.photo" />
      </b-tab>
    </b-tabs>
    <br />
    <Molded v-if="showSelectUser">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <Select title="Usuário Vinculado" field="user" :formName="formName" :required="showSelectUser"
            url="/api/v1/adm/user/select-all" v-model="collaborator.user">
          </Select>
        </b-col>
      </b-row>
      <br />
    </Molded>
    <br v-if="showSelectUser" />
    <Molded>
      <Contact :formName="formName" v-model="collaborator.contactRequest" />
    </Molded>
    <div v-if="id && !isLoading('panel')">
      <br />
      <b-tabs v-model="tabIndex">
        <b-tab title="Endereço">
          <AddressList v-if="tabIndex == 0" :genericId="id" />
        </b-tab>
        <b-tab title="Anexo">
          <FileManager :showInModal="false" source="collaborator" :genericId="collaborator.id" />
        </b-tab>
      </b-tabs>
    </div>
    <br />
    <Molded>
      <TextArea title="Observações" field="observation" :required="false" :maxLength="800"
        v-model="collaborator.observation" />
    </Molded>
    <br />
  </div>
</template>

<script>
import ImageUpload from "@nixweb/nixloc-ui/src/component/forms/ImageUpload";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import FileManager from "@nixweb/nixloc-ui/src/component/shared/file-manager/FileManager";

import AddressList from "../../shared/address/AddressList.vue";

import Contact from "@nixweb/nixloc-ui/src/component/value-objects/Contact.vue";
import Person from "@nixweb/nixloc-ui/src/component/value-objects/Person.vue";

import Collaborator from "@/components/modules/human-resources/collaborator/Collaborator.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CollaboratorCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: {
    ImageUpload,
    InputText,
    Select,
    RadioGroup,
    TextArea,
    CheckboxGroup,
    FileManager,
    Person,
    Contact,
    Molded,
    AddressList,
    AddressList,
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "collaboratorCreateUpdate",
      collaborator: new Collaborator(),
      urlCreate: "/api/v1/human-resources/collaborator/create",
      urlUpdate: "/api/v1/human-resources/collaborator/update",
      urlGetById: "/api/v1/human-resources/collaborator/get-by-id",
      tabIndex: 0,
    };
  },
  created() {
    if (this.id) this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
    showSelectUser() {
      return this.collaborator.options.includes("isConsultant");
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["removeLoading", "hideVodal"]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    create() {
      this.collaborator.addressValueObject.state = "";

      let params = { url: this.urlCreate, obj: this.collaborator };
      this.postApi(params).then((response) => {
        if (response.success && this.registerInSelect) {
          this.$emit("input", {
            id: response.data.id,
            content: response.data.person.companyName,
          });
          this.hideVodal(this.formName);
        }
        if (response.success && !this.registerInSelect) {
          this.$router.replace({
            name: "collaboratorUpdate",
            params: { id: response.content.id },
          });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      this.collaborator.addressValueObject.state = "";
      let params = { url: this.urlUpdate, obj: this.collaborator };
      this.putApi(params).then(() => {
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    updateImage() {
      if (this.id) this.update();
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.collaborator.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}
</style>
